export const paginationProps = {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
};

export const fieldsProviders = [
    {key: 'brand', label: 'Бренд'},
    {key: 'name', label: "Ім'я Організації"},
    {key: 'is_pipeline', label: "Магістральні канали"},
    {key: 'is_paper_less', label: "Електронний документообіг"},
    {key: ('edr'), label: 'ЕДРПОУ/ІПН'},
    {key: 'rating', label: 'Рейтинг', _style: 'width:8%'},
    {key: 'show_details', label: 'Деталі', _style: 'width:5%', sorter: false, filter: false},
    {key: 'is_verify', label: 'Верифікація', _style: 'width:130px'},
    {key: 'is_blocked', label: 'Блокування', _style: 'width:9%'},
    {key: 'ipn', label: '', _style: 'width:1%', sorter: false},
];
export const fieldsProviderUsersDetails = [
    {key: 'email', label: 'Емейл'},
    {key: 'name', label: "Ім'я"},
    {key: 'phones', label: 'Контактні телефони'},
    {key: 'notification', label: 'Оповіщення'},
    {key: 'role', label: 'Права доступу'}
];

export const fieldsEmailHistory = [
    {key: 'to_email', label: 'Кому'},
    {key: 'subject', label: 'Тема повідомлення'},
    {key: 'created_at', label: 'Перша спроба'},
    {key: 'updated_at', label: 'Повторна спроба'},
    {key: 'count', label: 'К-сть спроб', _style: 'width:7%'},
    {key: 'resend', label: 'Повторити відправлення', sorter: false},
    {key: 'sent', label: 'Статус відправлення', sorter: false},
];

export const fieldsTenders = [
    {key: 'created_at', label: 'Старт тендеру'},
    {key: 'branch_a_address', label: 'Адреса точки А'},
    {key: 'branch_b_address', label: "Адреса точки Б"},
    {key: 'channel_purpose', label: 'Призначення'},
    {key: 'connect_type', label: 'Тип каналу'},
    {key: 'channel_speed', label: "Швидкість, Мбіт/с", _style: 'width:7%',},
    {key: 'end_tender', label: 'Закінчення тендеру', _style: 'width: 5%'},
    {key: 'status', label: 'Статус тендера ', _style: 'width:5%',},
    {key: 'offer_status', label: 'Статус пропозиції', _style: 'width: 5%'},
    {key: 'branch_a', label: '', sorter: false, _style: 'display:none;',},
    {key: 'id', label: '', sorter: false, _style: 'display:none;',},
    {key: 'merchant', label: '', _style: 'display:none;',},
];

export const fieldsOrder = [
    {key: 'created_at', label: 'Створення заявки'},
    {key: 'branch_a_address', label: 'Адреса точки А'},
    {key: 'branch_b_address', label: "Адреса точки Б"},
    {key: 'consumer_type', label: 'Вид каналу'},
    {key: 'channel_purpose', label: 'Призначення'},
    {key: 'connect_type', label: 'Тип каналу'},
    {key: 'channel_speed', label: "Швидкість, Мбіт/с", _style: 'width:7%',},
    {key: 'end_tender', label: 'Закінчення тендеру', _style: 'width:5%'},
    {key: 'status', label: 'Статус заявки', _style: 'width:5%',},
    {key: 'branch_a', label: '', sorter: false, _style: 'width:1%',},
    {key: 'id', label: '', sorter: false, _style: 'width:1%',},
    {key: 'merchant', label: '', _style: 'display:none;',},
];

export const fieldsAllOffersWin = [
    {key: 'provider_name', label: "Організація"},
    {key: 'provider_brand', label: "Провайдер"},
    {key: 'connection_cost', label: 'Вартість підключення'},
    {key: 'monthly_payment', label: 'Щомісячна оплата'},
    {key: 'speed', label: 'Швидкість, Мбіт/с (Вх/Вих) (Європа)'},
    {key: 'physics_id', label: 'Фізика'},
    {key: 'iface_id', label: 'Інтерфейс'},
    {key: 'protocol_id', label: 'Протокол'},
    {key: 'days_connect', label: 'Час для організації канала (Роб.днів)'},
    {key: 'proposal_result', label: 'Вартість на рік', _style: 'width:5%'},
    {key: 'show_more', label: 'Деталі'},
    {key: 'changeWINNER', label: 'Вибрати Переможця'},
];
export const fieldsAllOffersClose = [
    {key: 'provider_name', label: "Організація"},
    {key: 'provider_brand', label: "Провайдер"},
    {key: 'connection_cost', label: 'Вартість підключення'},
    {key: 'monthly_payment', label: 'Щомісячна оплата'},
    {key: 'speed', label: 'Швидкість, Мбіт/с (Вх/Вих) (Європа)'},
    {key: 'physics_id', label: 'Фізика'},
    {key: 'iface_id', label: 'Інтерфейс'},
    {key: 'protocol_id', label: 'Протокол'},
    {key: 'days_connect', label: 'Час для організації канала (Роб.днів)'},
    {key: 'proposal_result', label: 'Вартість на рік', _style: 'width:5%'},
    {key: 'show_more', label: 'Деталі'},
    {key: 'isWINNER', label: 'Переможець'},
];
export const fieldsOffersWithoutWin = [
    {key: 'provider_name', label: "Організація"},
    {key: 'provider_brand', label: "Провайдер"},
    {key: 'connection_cost', label: 'Вартість підключення'},
    {key: 'monthly_payment', label: 'Щомісячна оплата'},
    {key: 'speed', label: 'Швидкість, Мбіт/с (Вх/Вих) (Європа)'},
    {key: 'physics_id', label: 'Фізика'},
    {key: 'iface_id', label: 'Інтерфейс'},
    {key: 'protocol_id', label: 'Протокол'},
    {key: 'days_connect', label: 'Час для організації канала (Роб.днів)'},
    {key: 'proposal_result', label: 'Вартість на рік', _style: 'width:5%'},
    {key: 'show_more', label: 'Деталі'},
];
export const fieldsProviderProposals = [
    {key: 'branch_a', label: 'Бранч/Адреса'},
    {key: 'type_channel', label: "Тип каналу"},
    {key: 'download_speed', label: 'Швидкість, Мбіт/с (Вх/Вих) (Європа)'},
    {key: 'connection_cost', label: 'Підключення, грн з ПДВ'},
    {key: 'monthly_payment', label: 'Абонплата, грн з ПДВ'},
    {key: 'status_order', label: 'Статус тендера'},
    {key: 'status', label: 'Статус пропозиції'},
];

export const fieldsManagersITRP = [
    {key: 'mrm', label: 'МРУ'},
    {key: 'branch_mnemonic', label: 'Бранч'},
    {key: 'login', label: 'Логін'},
    {key: 'name', label: 'П.І.Б.'},
    {key: 'delete', label: ''}
];