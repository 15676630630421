<template v-if="can('view_page_email_history_bank_user')">
  <CRow>
    <modal-any-information
        :title="openModalValue?.title"
        :show-any-information-modal="alertModalShow"
        @close="alertModal()"
    >
      <template v-slot:body>
        <p v-html="openModalValue?.message"></p>
      </template>
    </modal-any-information>
    <CCol>
      <CCard>
        <CCardHeader><h2>Архів відправлених emails</h2></CCardHeader>
        <CCardBody>
          <CRow v-if="spinner" class="justify-content-center">
            <CSpinner color="primary" style="width:4rem;height:4rem;"/>
          </CRow>
          <email-history-grid
              :email_list="historyEmails"
              :count-page="countPage"
              @showModalErrorSend="(data) => showModalMessage(data)"
              @showModalSendMessage="(data) => showModalMessage(data)"
              @resendMailSend="(mailData) => resendMail(mailData)"
              @updateTableData="(e) => updateTable({...e})"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from "axios";
import timeConvertMixin from '@/mixins/timeConvertMixin'
import statusTenderAndPropositionMixin from "@/mixins/statusTenderAndPropositionMixin";
import EmailHistoryGrid from "~/Components/Grid/EmailHistoryGrid.vue";
import modalAnyInformation from "~/Components/Modal/ModalAnyInformation.vue";
import {can} from "@/rules/permissions";

export default {
  name: "AdminEmailHistory",
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  components: {EmailHistoryGrid, modalAnyInformation},

  data() {
    return {
      historyEmails: null,
      countPage: 0,
      spinner: false,
      tableSpinner: null,
      alertModalShow: false,
      openModalValue: null,
      upd: null,
    }
  },
  methods: {
    can,
    alertModal(show = false) {
      this.alertModalShow = show;
    },
    showModalMessage(data = null) {
      this.alertModal(true);
      console.log(data)
      if (data) this.openModalValue = {message: data?.message || data, title: data?.to_email || 'Надіслане повідомлення'} || data;
    },
    getInfoEmails(limit = 0, page = 1, columnSorter, tableFilter = '') {
      let self = this;
      let sort = null;
      let tabFilter = '';
      sort = columnSorter?.asc

      if (sort) sort = 'asc';
      else sort = 'desc';

      if (tableFilter) tabFilter = `&search=${tableFilter}`

      self.spinner = true;
      axios.get(`/api/emails?limit=${limit}&sort=${sort}&order_by=${columnSorter?.column || 'updated_at'}&offset=${limit * (page - 1)}${tabFilter}`)
          .then((resp) => {
            self.spinner = false;
            self.historyEmails = resp.data;
            // if (!Array.isArray(self.historyEmails))self.historyEmails=[];
            self.historyEmails = self.historyEmails ?? [];
          }).catch(() => {
        self.spinner = false;
      })
    },
    resendMail(data) {
      let self = this;
      self.tableSpinner = data;
      axios.post(`/api/emails/send`, {
        'to_email': data.to_email,
        'subject': data.subject,
        'message': data.message,
        'id': data.id
      }).then(function () {
        self.tableSpinner = null;
        self.updateTable(self.upd);
      }).catch(function () {
        self.tableSpinner = null;

      });
    },
    getMailPagination(tableFilter) {
      let self = this;
      let tabFilter = '';

      if (tableFilter) tabFilter = `?search=${tableFilter}`
      axios.get(`/api/emails/count${tabFilter}`)
          .then((response) => self.countPage = response.data)
          .catch(() => self.countPage = 0);
    },
    updateTable(e) {
      // console.log('sdasd', {...e})

      // this.spinner = true;
      this.upd = e;
      this.getInfoEmails(e?.limit, e?.page, e?.columnSorter, e?.tableFilter);
      this.getMailPagination(e?.tableFilter);
    },
  }
}
</script>
