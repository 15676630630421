<template>
  <div>
    <template v-if="email_list">
      <CDataTable
          hover
          striped
          v-if="email_list"
          :items="email_list"
          :fields="fieldsEmailHistory"
          index-column
          :items-per-page-select="{label:'Кількість на сторінці: ', values:[5,10,20,30,50,100,200]}"
          :items-per-page="perPages"
          :sorter="{external:false, resetable:false}"
          :noItemsView="{ noResults: 'За результатами пошуку нічого не знайдено', noItems: 'За результатами пошуку нічого не знайдено' }"
          :table-filter="{lazy: false, placeholder: 'Знайти...', label: ' ', external:true}"
          @pagination-change="getPerPage"
          @update:sorter-value="getColumnSorter"
          @update:table-filter-value="getTableFilter"
      >
        <!--                            :sorter-value="{column:'updated_at', asc:false}"-->
        <template #created_at="data">
          <td>{{ timeConvert(data.item.created_at) }}</td>
        </template>
        <template #updated_at="data">
          <td>{{ data.item.updated_at === data.item.created_at ? "" : timeConvert(data.item.updated_at) }}</td>
        </template>
        <template #to_email="data">
          <td><a :href="`mailto:${data.item.to_email}`">{{ data.item.to_email }}</a></td>
        </template>
        <template #subject="data">
          <td>
            <CLink class="cursor-pointer" @click="showModalSendMessage(data.item)">
              {{ data.item.subject }}
            </CLink>
          </td>
        </template>
        <template #resend="data">
          <td>
            <CButton
                v-if="tableSpinner === data.item"
                class="py-1 px-4"
                disabled
            >
              <CSpinner
                  color="red"
                  size="sm"
              ></CSpinner>
            </CButton>
            <CButton
                v-else
                color="info"
                size="sm"
                :disabled="disabledBtn(data.item.count)"
                @click="resendMailSend(data.item)"
            >Повторити
            </CButton>
          </td>
        </template>
        <template #count="data">
          <td v-if="data.item.count !== 0">
            {{ data.item.count }}
          </td>
          <td v-else></td>
        </template>
        <template #sent="data">
          <td>
            <template v-if="data.item.sent">
              <CBadge
                  color="success"
                  class="px-3 py-2"
              >Надіслано
              </CBadge>
            </template>
            <template v-if="data.item.count === 0">
              <CBadge
                  color="warning"
                  class="px-3 py-2"
              >У черзі
              </CBadge>
            </template>
            <template v-if="!data.item.sent && data.item.count > 0">
              <CBadge
                  color="danger"
                  class="px-3 py-2 cursor-pointer hoverBadge"
                  @click="showModalErr(data.item.error)"
              >Помилка
              </CBadge>
            </template>
          </td>
        </template>
      </CDataTable>
      <CPagination
          v-if="paginationCount >= 1"
          align="center"
          :pages="Math.ceil(paginationCount)"
          :active-page.sync="activePage"
      ></CPagination>
    </template>
    <template v-else>
      <h4>Інформація про надіслані повідомлення відсутння! :(</h4>
    </template>
  </div>
</template>

<script>
import {fieldsEmailHistory} from "./Fields";
import statusTenderAndPropositionMixin from "@/mixins/statusTenderAndPropositionMixin";
import timeConvertMixin from '@/mixins/timeConvertMixin'
import btnCopy from "~/Components/Button/BtnCopy.vue";

export default {
  name: "EmailHistoryGrid",
  mixins: [statusTenderAndPropositionMixin, timeConvertMixin],
  components:{btnCopy},
  props: {
    email_list: {
      required: false,
      default: null,
      type: [Object, Array, null]
    },
    countPage: {
      required: false,
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      fieldsEmailHistory: fieldsEmailHistory,
      activePage: 1,
      tableSpinner: null,
      columnSorter: {
        asc: false,
        column: 'updated_at',
      },
      perPages: 20,
      newPerPage: 20,
      tableFilterVal: ''
    }
  },
  methods: {
    disabledBtn(v) {
      // return !v ? true : false;
      return !v;
    },
    getPerPage(e) {
      this.newPerPage = e;
    },
    getColumnSorter(e) {
      //column sorter
      this.columnSorter = e;
    },
    showModalErr(err) {
      this.$emit('showModalErrorSend', err);
    },
    showModalSendMessage(mail) {
      this.$emit('showModalSendMessage', mail);
    },
    getTableFilter(e) {
      if (e === '') {
        this.activePage = 1;
        this.tableFilterVal = '';
        return this.updateTable(this.newPerPage, this.activePage, this.columnSorter);
      }
      if (e.length >= 3 && e.length <= 150) {
        this.tableFilterVal = e;
        this.activePage = 1;
        return this.updateTable(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
      }
    },
    resendMailSend(data) {
      this.$emit('resendMailSend', data);
    },
    updateTable(limit, page, columnSorter, tableFilter) {
      this.$emit('updateTableData', {limit, page, columnSorter, tableFilter});
    },
  },
  created() {
    this.updateTable(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
  },
  computed: {
    tableColumnSort() {
      return {
        columnSorter: {
          column: this.columnSorter.column,
          asc: this.columnSorter.asc
        },
      }
    },
    paginationCount() {
      return this.$props.countPage / this.newPerPage;
    }
  },
  watch: {
    'activePage': function () {
      return this.updateTable(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
    },
    'newPerPage': function () {
      this.activePage = 1;
      return this.updateTable(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
    },
    tableColumnSort() {
      this.activePage = 1;
      return this.updateTable(this.newPerPage, this.activePage, this.columnSorter, this.tableFilterVal);
    },
  }
}
</script>
