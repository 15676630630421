<template>
  <CModal
      :title="title"
      :color="color"
      :size="size"
      :show.sync="showAnyInformationModal"
  >
    <template #body-wrapper>
      <CCardBody>
        <slot name="body"></slot>
      </CCardBody>
    </template>
    <template #footer>
      <slot name="footer">
        <CButton color="info" @click="$emit('close')">{{ textOK }}</CButton>
      </slot>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "ModalAnyInformation",
  props: {
    showAnyInformationModal: {
      required: true,
      type: Boolean,
      default: false
    },
    color: {
      required: false,
      default: 'info',
      type: String
    },
    textOK: {
      required: false,
      default: 'ОК',
      type: String
    },
    title: {
      required: false,
      default: '',
      type: String
    },
    size: {
      required: false,
      default: 'lg',
      type: String
    }
  },
  watch: {
    showAnyInformationModal(val) {
      if (!val) return this.$emit('close');
    }
  }
}
</script>
